body {
  font-family: 'Poppins', sans-serif;
  color: #0c0c0c;
  background-color: #ffffff;
  overflow-x: hidden;
}

.layout_padding {
  padding: 90px 0;
}

.layout_padding2 {
  padding: 75px 0;
}

.layout_padding2-top {
  padding-top: 75px;
}

.layout_padding2-bottom {
  padding-bottom: 75px;
}

.layout_padding-top {
  padding-top: 90px;
}

.layout_padding-bottom {
  padding-bottom: 90px;
}

.heading_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
          
}

.heading_container h2 {
  position: relative;
  font-weight: bold;
}

.heading_container h2 span {
  color: #da7426;
}

.heading_container.heading_center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}

a,
a:hover,
a:focus {
  text-decoration: none!important;
}

a:hover,
a:focus {
  color: initial;
}

.btn,
.btn:focus {
  outline: none !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.backButton{
  display: none!important;
}

/*header section*/
.navbar.navbar-expand-lg{
  transition: all 0.5s linear;
}
.navbar-collapse{
  transition: all 0.5s linear;
}
.navbar-collapse.show{
  transition: all 0.5s linear;
}
.hero_area {
  position: relative;
  height:83vh!important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-image: url("../public/Images/hero-bg.jpg");
  background-size: cover!important;
}
.hero_area::after{
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0; bottom: 0; left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.sub_page .hero_area {
  height: auto;
  
}

.sub_page .header_section .header_bottom {
  background-color: #A2B5BB;
  transition: all 0.5s linear !important;
  
}

.header_section {
  border-top: 15px solid #A41C1B;
  z-index: 2;
  transition: all 0.5s linear !important;
}

.header_section .header_top {
  padding:5px 0!important;
  background-color: #003471;
  transition: all 0.5s linear !important;
}

.header_section .header_top .header_top_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;   
  transition: all 0.5s linear !important;
}

.header_section .header_top .contact_nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  
}

.header_section .header_top .contact_nav a {
  color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-decoration: none;
}

.header_section .header_top .contact_nav a i {
  margin-right: 5px;
  background-color: #A2B5BB;
  width: 35px;
  height: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #ffffff;
  border-radius: 100%;
}

.header_section .header_top .contact_nav a:hover i {
  background-color: #A41C1B;
}

.header_section .header_top .social_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.header_section .header_top .social_box a{
  text-decoration: none;
}

.header_section .header_top .social_box a i {
  margin-right: 5px;
  background-color: #A2B5BB;
  width: 28px;
  height: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #ffffff;
  border-radius: 100%;

}

.header_section .header_top .social_box a:hover i {
  background-color: #A41C1B;
  transition: all 0.5s linear !important;
}

.header_section .header_bottom {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 0!important;
  transition: all 0.5s linear !important;
}

.header_section .header_bottom .container-fluid {
  padding-right: 25px;
  padding-left: 25px;
  transition: all 0.5s linear !important;
}

.navbar-brand {
  color: #ffffff;
  font-weight: bold;
  font-size: 24px;
}

.navbar-brand span {
  color: #A41C1B;
}

.navbar-brand:hover {
  color: inherit;
}

.navbar_brand_mobile {
  display: none;
}

.custom_nav-container {
  padding: 0;
  transition: all 0.5s linear !important;
}

.custom_nav-container .navbar-nav {
  margin: auto;
  transition:all 0.5s linear ;
}

.custom_nav-container .navbar-nav .nav-item .nav-link {
  padding: 5px 30px;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  font-size: 15px;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.custom_nav-container .navbar-nav .nav-item .nav-link i {
  margin-right: 5px;
}

.custom_nav-container .navbar-nav .nav-item:hover .nav-link {
  color: #A41C1B;
}

.custom_nav-container .nav_search-btn {
  width: 35px;
  height: 35px;
  padding: 0;
  border: none;
  color: #ffffff;
}

.custom_nav-container .nav_search-btn:hover {
  color: #A41C1B;
}

.custom_nav-container .navbar-toggler {
  outline: none;
}

.custom_nav-container .navbar-toggler {
  padding: 0;
  width: 37px;
  height: 42px;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.custom_nav-container .navbar-toggler span {
  display: block;
  width: 35px;
  height: 4px;
  background-color: #ffffff;
  margin: 7px 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  border-radius: 5px;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.custom_nav-container .navbar-toggler span::before, .custom_nav-container .navbar-toggler span::after {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  top: -10px;
  border-radius: 5px;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.custom_nav-container .navbar-toggler span::after {
  top: 10px;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] span {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] span::before, .custom_nav-container .navbar-toggler[aria-expanded="true"] span::after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  top: 0;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-1 {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  margin: 0;
  margin-bottom: -4px;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-2 {
  display: none;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-3 {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  margin: 0;
  margin-top: -4px;
}

.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-1,
.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-2,
.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-3 {
  -webkit-transform: none;
          transform: none;
}

/*end header section*/
/* slider section */
.slider_section {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 100;
}

.slider_section .dot_design {
  position: absolute;
  width: 450px;
  right: 0;
  bottom: 0;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  z-index: 1;
}

.slider_section .dot_design img {
  width: 100%;
}

.slider_section .row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.slider_section #customCarousel1 {
  width: 100%;
  position: unset;
}

.slider_section .detail-box {
  color: #A41C1B;

}

.slider_section .detail-box h1 {
  font-weight: 600;
  margin-bottom: 0;
  color: #ffffff;
  font-size: 3rem;
  letter-spacing: 2px;
}

.slider_section .detail-box p {
  color: #6d6d6d;
}

.slider_section .detail-box .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -5px;
  margin-top: 45px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.slider_section .detail-box .btn-box a {
  margin: 5px;
  text-align: center;
  width: 165px;
}

.slider_section .detail-box .btn-box .btn1 {
  display: inline-block;
  padding: 10px 15px;
  background-color: #ffffff;
  color: #000000;
  border-radius: 0;
  -webkit-transition: all .3s;
  transition: all .3s;
  border: 1px solid #ffffff;
}

.slider_section .detail-box .btn-box .btn1:hover {
  background-color: transparent;
  color: #ffffff;
}

.slider_section .detail-box .btn-box .btn2 {
  display: inline-block;
  padding: 10px 15px;
  background-color: #A41C1B;
  color: #ffffff;
  border-radius: 0;
  -webkit-transition: all .3s;
  transition: all .3s;
  border: 1px solid #A41C1B;
}

.slider_section .detail-box .btn-box .btn2:hover {
  background-color: transparent;
  color: #da7426;
}

.slider_section .img-box img {
  width: 100%;
}

.slider_section .carousel_btn-box {
  position: absolute;
  right: 25px;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 105px;
  height: 50px;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  bottom: 50px!important;
}

.slider_section .carousel_btn-box a {
  position: unset;
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  opacity: 1;
  border-radius: 100%;
  color: #003471;
  font-size: 14px;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  -webkit-transition: all .2s;
  transition: all .2s;
}

.slider_section .carousel_btn-box a:hover {
  background-color: #A41C1B;
  color: #ffffff;
}
/* About Section */
.about_section .row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.about_section .img-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.about_section .img-box img {
  max-width: 100%;
  position: relative;
  z-index: 2;
  border: 5px solid #A41C1B;
  border-radius:60% 40% 30% 40%;
}

.about_section .detail-box p {
  color: #1f1f1f;
  margin-top: 15px;
}

.about_section .detail-box a {
  display: inline-block;
  padding: 10px 35px;
  background-color: #da7426;
  color: #ffffff;
  border-radius: 0px;
  -webkit-transition: all .3s;
  transition: all .3s;
  border: 1px solid #da7426;
  margin-top: 15px;
}

.about_section .detail-box a:hover {
  background-color: transparent;
  color: #da7426;
}

.portfolio_section {
  background-color: #A2B5BB;
  color: #ffffff;
  padding-top: 45px;
}

.portfolio_section .heading_container {
  margin-bottom: 25px;
}

.portfolio_section .filter_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.portfolio_section .filter_box .owl-filter-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.portfolio_section .filter_box .owl-filter-bar a {
  text-transform: uppercase;
  color: #ffffff;
  padding: 5px 10px;
  font-size: 15px;
  border-radius: 2px;
  margin: 5px 0;
}

.portfolio_section .filter_box .owl-filter-bar a.active {
  color: #ffffff!important;
  background-color: #A41C1B!important;
}

.portfolio_section .box {
  position: relative;
}

.portfolio_section .box .img-box {
  position: relative;
}

.portfolio_section .box .img-box img {
  width: 100%;
}

.portfolio_section .box .img-box .btn_overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  background-color: rgba(255, 255, 255, 0.8);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transform: translate(-50%, -50%) scale(0);
          transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.portfolio_section .box .img-box .btn_overlay a {
  padding: 10px 45px;
  border: 1px solid #A41C1B;
  color: #A41C1B;
}

.portfolio_section .box .img-box .btn_overlay a:hover {
  background-color: #A41C1B;
  color: #ffffff;
}

.portfolio_section .box .img-box:hover .btn_overlay {
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}

.portfolio_section .owl-item.active.center .box .detail-box {
  opacity: 1;
  visibility: visible;
}

.portfolio_section .portfolio_carousel {
  margin-top: 35px;
}

.portfolio_section .portfolio_carousel .owl-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 20px;
}

.portfolio_section .portfolio_carousel .owl-nav button {
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  outline: none;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 100%;
  color: #000000;
}

.portfolio_section .portfolio_carousel .owl-nav button:hover {
  background-color: #A41C1B;
  color: #ffffff;
}

.portfolio_section .portfolio_carousel .owl-nav button.owl-prev {
  left: 25px;
}

.portfolio_section .portfolio_carousel .owl-nav button.owl-next {
  right: 25px;
}

.service_section {
  position: relative;
}

.service_section .box {
  margin-top: 30px;
  text-align: center;
  -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.15);
          box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.15);
  padding: 25px 15px;
  -webkit-transition: all .3s;
  transition: all .3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.service_section .box .img-box {
  width: 65px;
  height: 65px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.service_section .box .img-box img {
  max-height: 100%;
  max-width: 100%;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.service_section .box .detail-box {
  margin-top: 15px;
}

.service_section .box .detail-box h5 {
  font-weight: bold;
}

.service_section .box .detail-box p {
  margin: 0;
}

.service_section .box:hover {
  background-color: #da7426;
  color: #ffffff;
}

.service_section .box:hover .img-box img {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

.service_section .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 45px;
}

.service_section .btn-box a {
  display: inline-block;
  padding: 10px 45px;
  background-color: #A41C1B;
  color: #ffffff;
  border-radius: 0;
  -webkit-transition: all .3s;
  transition: all .3s;
  border: 1px solid #A41C1B;
}

.service_section .btn-box a:hover {
  background-color: transparent;
  color: #A41C1B;
}

.contact_section {
  position: relative;
}

.contact_section .heading_container {
  margin-bottom: 45px;
}

.contact_section .row {
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

.contact_section .form_container {
  background-color: #A2B5BB;
  padding: 45px;
}
.contact_section .formSet{
  position: relative;
}
.contact_section .form_container .form-control {
  width: 100%;
  border: none;
  height: 50px;
  margin-bottom: 15px;
  padding-left: 15px;
  outline: none;
  color: #101010;
  -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.15);
  border-radius: 0;
 
}

.contact_section .form_container .form-control::-webkit-input-placeholder {
  color: #565554;
}

.contact_section .form_container .form-control:-ms-input-placeholder {
  color: #565554;
}

.contact_section .form_container .form-control::-ms-input-placeholder {
  color: #565554;
}

.contact_section .form_container .form-control::placeholder {
  color: #565554;
}

.contact_section .form_container .form-control.message-box {
  height: 95px;
}
.contact_section .form_container .errorText{
  position: absolute!important;
  bottom: 0!important;
  right: 10px!important;
}
.contact_section .form_container .btn_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.contact_section .form_container .btn_box button {
  border: none;
  text-transform: uppercase;
  display: inline-block;
  padding: 12px 55px;
  background-color: #A41C1B;
  color: #ffffff;
  border-radius: 0px;
  -webkit-transition: all .3s;
  transition: all .3s;
  border: 1px solid #A41C1B;
}

.contact_section .form_container .btn_box button:hover {
  background-color: transparent;
  color: #A41C1B;
}

.contact_section .map_container {
  height: 100%;
  min-height: 375px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  padding: 0;
}

.contact_section .map_container .map {
  height: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.contact_section .map_container .map #googleMap {
  height: 100%;
}

.client_section .heading_container {
  margin-bottom: 45px;
}

.client_section .row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.client_section .client_id {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}

.client_section .client_id h5 {
  margin-top: 10px;
  margin-bottom: 0;
}

.client_section .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.client_section .box .img-box {
  width: 145px;
  min-width: 145px;
  position: relative;
  border: 10px solid #A41C1B;
}

.client_section .box .img-box img {
  width: 100%;
}

.client_section .box .detail-box {
  background-color: #ffffff;
  border: 1px solid #000000;
  padding: 15px;
  margin-bottom: 34px;
  margin-left: 1px;
}

.client_section .box .detail-box p {
  margin: 0;
}

.client_section .carousel-indicators {
  position: unset;
  margin: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.client_section .carousel-indicators li {
  background-color: #A41C1B;
  width: 20px;
  height: 20px;
  border: none;
  background-clip: unset;
  margin: 5px;
  border-radius: 100%;
  opacity: 1;
}

.client_section .carousel-indicators li.active {
  background-color: #A41C1B;
}

/* info section */
.info_section {
  position: relative;
  background-color: #252525;
  color: #ffffff;
}

.info_section h5 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 20px;
}

.info_section .info_logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 45px;
}

.info_section .info_logo .navbar-brand {
  font-size: 28px;
}

.info_section .social_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.info_section .social_box a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #ffffff;
  width: 30px;
  height: 30px;
  background-color: #A41C1B;
  border-radius: 100%;
  margin-right: 5px;
}

.info_section p {
  margin-bottom: 0;
  color: #878585;
}

.info_section a {
  text-transform: none;
}

.info_section ul {
  padding: 0;
}

.info_section ul li {
  list-style-type: none;
  margin: 3px 0;
}

.info_section ul li a {
  color: #878585;
}


.info_section .info_link-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.info_section .info_bottom .row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.info_section .info_contact {
  margin: 15px 0;
}

.info_section .info_contact .link-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 15px 0;
  color: #ffffff;
}

.info_section .info_contact .link-box i {
  width: 45px!important;
  height: 45px!important;
  line-height: 45px!important;
  border: 1px solid #A41C1B;
  text-align: center;
  border-radius: 5px;
  font-size: 18px;
  margin-right: 10px;
}

.info_form form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.info_form form input {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 45px;
  border: none;
  padding-left: 10px;
}

.info_form form button {
  width: 45px;
  height: 45px;
  background-color: #A41C1B;
  color: #ffffff;
  border: none;
  outline: none;
  font-size: 12px;
  margin: 0;
}

/* end info section */
/* footer section*/
.footer_section {
  position: relative;
  background-color: #ffffff;
  text-align: center;
}

.footer_section p {
  color: #252525;
  padding: 25px 0;
  margin: 0;
}

.footer_section p a {
  color: inherit;
}
/*# sourceMappingURL=style.css.map */