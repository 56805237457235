@media (max-width: 1300px) {
  .quote_btn-container {
    display: none;
  }

  .custom_nav-container .navbar-nav {
    align-items: center;
  }

  .custom_nav-container .navbar-nav .nav-item .nav-link {
    padding: 5px 15px;
  }
}

@media (max-width: 1120px) {}

@media (max-width: 992px) {
  .hero_area {
    height: auto;
  }

  .header_section .header_top {
    display: none;
  }

  .header_section .header_bottom {
    background-color: #CFD2CF;
  }

  .navbar_brand_mobile {
    display: flex;
  }

  .custom_nav-container .navbar-nav {
    padding-top: 15px;
  }

  .header_section .header_top .contact_nav a span {
    display: none;
  }

  .custom_nav-container .navbar-nav .nav-item .nav-link {
    padding: 5px 25px;
    margin: 5px 0;
  }

  .slider_section .detail-box h1 {
    font-size: 2.5rem;
  }

  .slider_section {
    padding: 75px 0;
  }

  .contact_section .form_container {
    background-color: #A2B5BB;
    padding: 45px 25px;
  }
  .backButton{
    display: block!important;
  }
}

@media (max-width: 767px) {

  .slider_section .detail-box .btn-box {
    justify-content: center;
  }

  .about_section .img-box {
    margin-top: 45px;
  }

  .project_section .box .detail-box {
    opacity: 1;
    visibility: visible;
  }

  .contact_section .form_container .btn_box {
    justify-content: center;
  }

  .client_section .carousel-indicators {
    flex-direction: row;
  }

  .info_section .row>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .info_section .info_main .rov>div {
    margin-bottom: 25px;
  }

  .info_section .social_box {
    margin-bottom: 25px;
  }

}

@media (max-width: 576px) {
  .header_section .header_top .social_box {
    display: none;
  }

  .header_section .header_top .contact_nav a {
    margin: 0;
    margin-left: 15px;
  }
  
  .client_section .box {
    flex-direction: column;
    align-items: center;
  }

  .client_section .box .detail-box {
    margin-top: 15px;
    margin-left: 0;
  }
}

@media (max-width: 480px) {}

@media (max-width: 420px) {}

@media (max-width: 376px) {}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}